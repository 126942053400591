@tailwind base;
@tailwind components;
@tailwind utilities;


.size-1 {
    grid-row: span 1;
    grid-column: span 1;
  }
  
  .size-2 {
    grid-row: span 2;
    grid-column: span 2;
  }
  
  .size-3 {
    grid-row: span 1;
    grid-column: span 2;
  }
  
  .size-4 {
    grid-row: span 2;
    grid-column: span 1;
  }

  .react-tel-input .form-control {
    @apply !w-full py-6 !text-base;
  }
  
  .opt-container {
    @apply flex justify-between gap-2;
  }
  .opt-container input {
    @apply !mr-0 flex-1 py-5 outline-none;
  }